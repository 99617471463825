import { useStaticQuery, graphql } from "gatsby"
import { useGetTotalFiltersQuery as useGetTotalFiltersQueryTokko } from "../redux/originalTokkoApi/properties"
import { useGetSummaryQuery as useGetTotalFiltersQueryMediacore } from "../redux/mediacore/properties"
function useQuerySummary(props) {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        keys {
          tokko
        }
        env {
          CLIENTID
        }
      }
    }
  `)


  const {
    data: allSummaryData,
    isLoading,
    isError,
    isFetching,
  } = realEstate.keys.tokko //EDIT
    ? useGetTotalFiltersQueryTokko({
        API_KEY:realEstate.keys.tokko,
        OPERATION:props?.OPERATION,
        TYPE:props?.TYPE
      })
    : useGetTotalFiltersQueryMediacore({
      CLIENT_ID:realEstate.env.CLIENTID,
      OPERATION:props?.OPERATION,
      TYPE:props?.TYPE
    })

  return {
    allSummaryData,
    isLoading,
    isError,
    isFetching,
    isMediacore: realEstate.keys.tokko ? false : true,
  }
}

export default useQuerySummary
